import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
	<Layout>
		<SEO
			title="Page Not Found"
			description="The page or file you are looking for does not exist; it may have been moved, or removed altogether."
		/>
		<h1>Page Not Found</h1>
		<p>The page or file you are looking for does not exist; it may have been moved, or removed altogether.</p>
	</Layout>
);

export default NotFoundPage;
