import React from 'react';
import PropTypes from 'prop-types';

import './layout.css';

const Layout = ({ children }) => <main className="content">{children}</main>;

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
